<script setup>
  import SharedContactInfo from '@/components/shared/contact/SharedContactInfo.vue';
  import SharedContactOffices from '@/components/shared/contact/SharedContactOffices.vue';
  import { ref } from 'vue';

  const hover = ref(false);

  const setHover = (newHover) => {
    hover.value = newHover;
  }

</script>

<template>
  <section class='responsive-container shared-contact'>
    <div class='row shared-contact-body'>
      <div
        class='office-img'
        :class="hover ? 'madrid-office' : 'mtp-office'"
        data-test-id='shared-contact-img'
      />
      <div class='contact-info'>
        <SharedContactOffices 
          class='shared-contact-offices' 
          @hover='setHover'
        />
        <SharedContactInfo class='shared-contact-info' />
      </div>
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .shared-contact {
    .shared-contact-body {
      display: flex;
      flex-direction: column;
      @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $spacer-md;
      }
      .office-img {
        border-radius: 18px;
        filter: drop-shadow(0px 10px 20px rgba(41, 41, 42, 0.07));
        background-size: cover;
        background-position: 41%;
      }
      .contact-info {
        display: flex;
        flex-direction: column;
        grid-gap: $spacer-lg;
      }
      .shared-contact-info {
        display: flex;
        flex-direction: column;
        grid-gap: $spacer-lg;
      }
      .mtp-office {
        background-image: url('@/../app/assets/images/img_office/montpellier.jpg')
      }
      .madrid-office {
        background-image: url('@/../app/assets/images/img_office/madrid.jpg')
      }
    }
  }
</style>
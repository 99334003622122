<script setup>
  import franceInvest from '@/../app/assets/images/img_esg/france-invest.svg';
  import pri from '@/../app/assets/images/img_esg/pri.svg';

  const imageMapper = {
    franceInvest: franceInvest,
    pri: pri
  }

  defineProps({
    image: { type: String, default: '', required: true }
  })
</script>

<template>
  <img
    :src='imageMapper[image]'
    :alt='$t(`esg.partners.${image}`)'
    class='esg-partners-item'
    data-test-id='esg-partners-item-img'
  >
</template>

<style lang='scss'>
  .esg-partners-item {
    text-align: center;
  }
</style>

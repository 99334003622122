<script setup>
  import efficiency from '@/../app/assets/images/img_home/energy/efficiency.webp';
  import hydro from '@/../app/assets/images/img_home/energy/hydro.webp';
  import hydrogen from '@/../app/assets/images/img_home/energy/hydrogen.webp';
  import mobility from '@/../app/assets/images/img_home/energy/mobility.webp';
  import solar from '@/../app/assets/images/img_home/energy/solar.webp';
  import wind from '@/../app/assets/images/img_home/energy/wind.webp';

  const imageMapper = {
    efficiency: efficiency,
    hydro: hydro,
    hydrogen: hydrogen,
    mobility: mobility,
    solar: solar,
    wind: wind
  }

  const darkFilter = 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))';
  const backgroundImage = `${darkFilter}, url(${imageMapper[props.slug]})`

  const props = defineProps({
    icon: { type: String, default: '' },
    slug: {type: String, default: ''}
  })
</script>

<template>
  <div class='energy-card'>
    <FontAwesomeIcon
      class='energy-icon'
      :icon="['fal', `fa-${icon}`]"
    />
    <p
      class='energy-label'
      data-test-id='home-energy-card-label'
    >
      {{ $t(`home.energy.card.${slug}`) }}
    </p>
  </div>
</template>

<style lang='scss'>
  .energy-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $dark;
    color: white;
    border-radius: 18px;
    border: 1px solid #E5E8E7;
    filter: drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.05));
    background: v-bind(backgroundImage);
    background-size: cover;
    padding: $spacer-md;
    min-height: 160px;
    @media(min-width: 1024px){
      min-width: 20vw;
      min-height: 280px;
      }

    .energy-icon { 
      height: 48px;
      padding: $spacer-s;
      align-self: start;
      @media(min-width: 1024px){
        height: 72px;
      }
    }
    .energy-label {
      font-family: $headings-font-family;
      font-size: $typer-xl;
      font-weight: 300;
      word-break: break-word;  
    }
  }
 </style>
<script setup>
  import mission from '@/../app/assets/images/img_esg/mission.jpg';
  import policy from '@/../app/assets/images/img_esg/policy.jpg';
  import approach from '@/../app/assets/images/img_esg/approach.jpg';

  defineProps({
    section: { type: String, default: '' }
  })

  const imageMapper = {
    mission: mission,
    policy: policy,
    approach: approach
  }
</script>

<template>
  <div
    :id='`${section}-section`'
    class='shared-esg-header'
    :style="`background-image: url('${imageMapper[section]}')`"
  >
    <div class='header-title arc-title'>
      <h2
        class='header-main-title'
        data-test-id='shared-esg-header-main-title'
      >
        {{ $t(`shared.esgHeader.${section}Title`) }}
      </h2>
      <p class='header-subtitle'>
        {{ $t(`shared.esgHeader.${section}Description`) }}
      </p>
    </div>
  </div>
</template>
<style scoped lang='scss'>
  .shared-esg-header {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    height: 50vh;
    background-position: center;
    background-size: cover;
    border-radius: 18px;
    border: 2px solid white;
    .header-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background: white;
      border-radius: 18px 18px 0px 0px;
      color: $dark;
      padding: 36px 36px 0px;
      max-width: 856px;
      width: 85%;
      .header-main-title {
        font-family: $headings-font-family;
        font-weight: 400;
      }
      .header-subtitle {
        color: $secondary;
        font-family: $font-family-sans-serif;
        font-size: $typer-lg;
        margin-bottom: $spacer-s;
      }
    }
  }
</style>
import frEsgApproachDescription from '@/content/esg/approach-description.fr.md';
import frEsgCompanyExclusions from '@/content/esg/company-exclusions.fr.md';
import frEsgExclusionsDescription from '@/content/esg/exclusions-description.fr.md';
import frEsgMetricsDescription from '@/content/esg/metrics-description.fr.md';
import frEsgPolicyDescription from '@/content/esg/policy-description.fr.md';

import frHomeExpertiseDescription from '@/content/home/expertise/description.fr.md';
import frHomeMissionDescription from '@/content/home/mission/description.fr.md';

import frLegalNoticeCopyright from '@/content/legal-notice/copyright.fr.md';
import frLegalNoticeEnerfipGestion from '@/content/legal-notice/enerfip-gestion.fr.md';
import frLegalNoticeHostContact from '@/content/legal-notice/host-contact.fr.md';
import frLegalNoticePersonalData from '@/content/legal-notice/personal-data.fr.md';
import frLegalNoticeResponsabilities from '@/content/legal-notice/responsabilites.fr.md';

import frStoresTeamMemberDescriptionBenjaminZarrouk from '@/content/stores/team-member/description-benjamin-zarrouk.fr.md';
import frStoresTeamMemberDescriptionDiegoMarty from '@/content/stores/team-member/description-diego-marty.fr.md';
import frStoresTeamMemberDescriptionEliudGarcia from '@/content/stores/team-member/description-eliud-garcia.fr.md';
import frStoresTeamMemberDescriptionEssalhaLakhoitri from '@/content/stores/team-member/description-essalha-lakhoitri.fr.md';
import frStoresTeamMemberDescriptionMaevaGranjon from '@/content/stores/team-member/description-maeva-granjon.fr.md';
import frStoresTeamMemberDescriptionMarionLoiseau from '@/content/stores/team-member/description-marion-loiseau.fr.md';
import frStoresTeamMemberDescriptionNicolasCastan from '@/content/stores/team-member/description-nicolas-castan.fr.md';
import frStoresTeamMemberDescriptionSebastienJamme from '@/content/stores/team-member/description-sebastien-jamme.fr.md';
import frStoresTeamMemberDescriptionVincentClerc from '@/content/stores/team-member/description-vincent-clerc.fr.md';

import frStrategyDescription from '@/content/strategy/description.fr.md';

import frTeamValuesValueBusinessTime from '@/content/team/values/value-business-time.fr.md';
import frTeamValuesValueLockOpen from '@/content/team/values/value-lock-open.fr.md';
import frTeamValuesValuePeopleGroup from '@/content/team/values/value-people-group.fr.md';
import frTeamValuesValueRadar from '@/content/team/values/value-radar.fr.md';

const contentMapper = {
  'fr': {
    'esg/approach-description': frEsgApproachDescription,
    'esg/company-exclusions': frEsgCompanyExclusions,
    'esg/exclusions-description': frEsgExclusionsDescription,
    'esg/metrics-description': frEsgMetricsDescription,
    'esg/policy-description': frEsgPolicyDescription,
    'home/expertise/description': frHomeExpertiseDescription,
    'home/mission/description': frHomeMissionDescription,
    'legal-notice/copyright': frLegalNoticeCopyright,
    'legal-notice/enerfip-gestion': frLegalNoticeEnerfipGestion,
    'legal-notice/host-contact': frLegalNoticeHostContact,
    'legal-notice/personal-data': frLegalNoticePersonalData,
    'legal-notice/responsabilities': frLegalNoticeResponsabilities,
    'stores/team-member/description-benjamin-zarrouk': frStoresTeamMemberDescriptionBenjaminZarrouk,
    'stores/team-member/description-diego-marty': frStoresTeamMemberDescriptionDiegoMarty,
    'stores/team-member/description-eliud-garcia': frStoresTeamMemberDescriptionEliudGarcia,
    'stores/team-member/description-essalha-lakhoitri': frStoresTeamMemberDescriptionEssalhaLakhoitri,
    'stores/team-member/description-maeva-granjon': frStoresTeamMemberDescriptionMaevaGranjon,
    'stores/team-member/description-marion-loiseau': frStoresTeamMemberDescriptionMarionLoiseau,
    'stores/team-member/description-nicolas-castan': frStoresTeamMemberDescriptionNicolasCastan,
    'stores/team-member/description-sebastien-jamme': frStoresTeamMemberDescriptionSebastienJamme,
    'stores/team-member/description-vincent-clerc': frStoresTeamMemberDescriptionVincentClerc,
    'strategy/description': frStrategyDescription,
    'team/values/value-business-time' : frTeamValuesValueBusinessTime,
    'team/values/value-lock-open' : frTeamValuesValueLockOpen,
    'team/values/value-people-group' : frTeamValuesValuePeopleGroup,
    'team/values/value-radar' : frTeamValuesValueRadar,
  }
}

export { contentMapper };

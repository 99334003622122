<script setup>
  import { liquidLoader } from '@/mixins/liquid-loader.js'
  import { ref, onMounted } from 'vue'

  const props = defineProps({
    value: { type: String, default: '' }
  })

  const valueDescription = ref('');

  onMounted(() => {
    liquidLoader(valueDescription, `team/values/value-${props.value.toLowerCase()}`);
  });
</script>

<template>
  <div class='team-values-item'>
    <div class='team-value-item-icon-wrapper'>
      <FontAwesomeIcon
        class='value-icon'
        :icon="['fat', `${value}`]"
      />
    </div>
    <div class='team-value-item-content'>
      <h3 data-test-id='team-values-item-title'>
        {{ $t(`team.values.item.${value}`) }}
      </h3>
      <p 
        class='value-description' 
        v-html='$sanitize(valueDescription)'
      />
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .team-values-item{
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-gap: $spacer-md;
    align-items: center;
    @media(min-width: 1024px){
      display: flex;
      flex-direction: row;
      text-align: left;
      align-items: start;
    }
    .team-value-item-content{
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-s;
    }
    .team-value-item-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 48px;
      min-height: 48px;
    }
    .value-icon{
      color: $primary;
      height: 48px;
      max-width: 100%;
    }
    .value-description {
      color: $secondary;
      font-size: $typer-md;
      font-weight: 300;
      @media(min-width: 1024px){
      text-align: justify;
      }
    }
  }
</style>

<script setup>
  import { liquidLoader } from '@/mixins/liquid-loader.js';
  import { ref, onMounted } from 'vue';

  import EsgApproachesHeader from './approaches/EsgApproachesHeader.vue';
  import EsgApproachesPriority from './approaches/EsgApproachesPriority.vue';
  import SharedEsgHeader from '@/components/shared/SharedEsgHeader.vue';
  import SharedRowList from '@/components/shared/SharedRowList.vue';

  const approachDescription = ref('');
  const companyExclusions = ref('');
  const exclusionsDescription = ref('');
  const metricsDescription = ref('');

  const exclusions = [
    { slug: 'nuclear', icon: 'radiation' },
    { slug: 'coal', icon: 'pickaxe'},
    { slug: 'fossil', icon: 'gas-pump' }
  ]
  const metrics = [
    { slug: 'co2', icon: 'temperature-high' },
    { slug: 'capacity', icon: 'bolt'},
    { slug: 'quantity', icon: 'house-building' }
  ]
  const priorities = [
    { priority: 'environment', icon: 'leaf' },
    { priority: 'social', icon: 'users'},
    { priority: 'gouvernance', icon: 'badge-check' }
  ]

  onMounted(() => {
    liquidLoader(approachDescription, 'esg/approach-description');
    liquidLoader(companyExclusions, 'esg/company-exclusions');
    liquidLoader(exclusionsDescription, 'esg/exclusions-description');
    liquidLoader(metricsDescription, 'esg/metrics-description');
  });
</script>

<template>
  <section class='esg-approches'>
    <SharedEsgHeader section='approach' />
    <div class='esg-section-content'>
      <p v-html='$sanitize(approachDescription)' />
      <div class='approach-step'>
        <EsgApproachesHeader
          step='exclusions'
          rank='1'
        />
        <p
          class='exclusions-description'
          v-html='$sanitize(exclusionsDescription)'
        />
        <SharedRowList
          class='exclusions'
          topic='esgExclusions'
          :items='exclusions'
          :bar='true'
        />
        <p v-html='$sanitize(companyExclusions)' />
      </div>
      <div class='approach-step'>
        <EsgApproachesHeader
          step='priorities'
          rank='2'
        />
        <p>{{ $t('esg.approaches.prioritiesDescription') }}</p>
        <EsgApproachesPriority
          v-for='priority in priorities'
          :key='priority.priority'
          :priority='priority.priority'
          :icon='priority.icon'
        />
      </div>
      <div class='approach-step'>
        <EsgApproachesHeader
          step='metrics'
          rank='3'
        />
        <SharedRowList
          class='metrics'
          topic='esgMetrics'
          :items='metrics'
        />
        <div
          class='shared-content-wrapper'
          v-html='$sanitize(metricsDescription)'
        />
      </div>
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .approach-step {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-md;
    .exclusions {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-md;
      padding: 24px;
      border: 1px solid $icons;
      border-radius: 18px;
      .energy-icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
      }
    }
    .metrics {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-md;
    }
  }
</style>

<script setup>
  import { liquidLoader } from '@/mixins/liquid-loader.js';
  import { ref, onMounted } from 'vue';

  const description = ref('');

  onMounted(() => {
    liquidLoader(description, 'home/mission/description');
  });
</script>

<template>
  <section class='home-mission'>
    <div class='arc-title mission-title'>
      <h2>{{ $t('home.mission.title') }}</h2>
    </div>
    <p
      class='description'
      v-html='$sanitize(description)'
    />
    <div>
      <router-link
        :to="{ name: 'strategy' }"
        class='cta-outline-primary'
        data-test-id='home-mission-link'
      >
        <div class='cta-label'>
          {{ $t('home.mission.link') }}
        </div>
        <div class='cta-icon-wrapper'>
          <FontAwesomeIcon 
            class='cta-icon-primary' 
            :icon="['fas', 'fa-info']" 
          />
        </div>
      </router-link>
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .home-mission {
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap:$spacer-lg;
    .description {
      font-size: $typer-lg;
      font-weight: 500;
      color: $secondary;
      text-align: center;
      @media(min-width: 1024px){
        padding: 0 10vw;
      }
    }
  }
</style>
<script setup>
  import energyCircle from '@/../app/assets/images/img_strategy/energy-circle.svg';
  import fundStrategy from '@/../app/assets/images/img_strategy/fund-strategy.png';
  import SharedBulletItem from '@/components/shared/SharedBulletItem.vue';
  import SharedStrategyTitle from '@/components/shared/SharedStrategyTitle.vue';

  const items = ['greenfield', 'support', 'diversification', 'risk']
</script>

<template>
  <section class='strategy-investment'>
    <SharedStrategyTitle
      icon='bullseye-arrow'
      slug='strategyInvestment'
    />
    <div class='strategy-section'>
      <img
        :src='energyCircle'
        :alt="$t('strategy.investment.energySchema')"
        data-test-id='energy-schema'
      >
      <div class='strategy-investment-content'>
        <div class='shared-bullet-list-group'>
          <SharedBulletItem
            v-for='item in items'
            :key='item'
            :item='item'
            component='investment'
          />
        </div>
      </div>
      <img 
        :src='fundStrategy'
        :alt="$t('strategy.investment.fundStrategy')" 
        class='fund-strategy'
        data-test-id='fund-strategy'
      >
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .strategy-investment {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-md;
    .strategy-section {
      text-align: center;
      img{
        width: 100%;
      }
      .fund-strategy {
        width: 100%;
      }
    }
    .strategy-investment-content {
      text-align: justify;
    }
  }
</style>

// imports from external files 
import { createApp } from 'vue/dist/vue.esm-bundler';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import matomo from 'vue-matomo'
import Vue3Sanitize from "vue-3-sanitize";

// components
import App from '@/App.vue';
import Contact from '@/pages/Contact.vue'
import Esg from '@/pages/Esg.vue';
import Home from '@/pages/Home.vue';
import LegalNotice from '@/pages/LegalNotice.vue';
import NotFound from '@/pages/NotFound.vue';
import Strategy from '@/pages/Strategy.vue';
import Team from '@/pages/Team.vue';

// imports from internal files other than components
import { i18n } from '@/i18n.js';
import { library } from '@/mixins/fontawesome-library.js';
import router from '@/router/router.js';

const app = createApp(App);
const head = createHead();
const pinia = createPinia();

app
  .component('Contact', Contact)
  .component('Esg', Esg)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .component('Home', Home)
  .component('LegalNotice', LegalNotice)
  .component('NotFound', NotFound)
  .component('Strategy', Strategy)
  .component('Team', Team)

app
  .use(head)
  .use(i18n)
  .use(matomo, {
    host: 'https://enerfip.matomo.cloud/',
    siteId: 3,
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    debug: false,
  })
  .use(pinia)
  .use(router)
  .use(Vue3Sanitize)

app.mount('#app');

app: 
  footer:
    accountInfoTitle: Comment créer un compte ?
    contactTitle: Contact
    customer:
      link:
        calendar: gestion{'@'}enerfip.eu
        phone: 04 84 80 00 12
    dataInfoTitle: Politique des données
    enerfipInfoTitle: Qui est Enerfip ?
    helpTitle: Aide et ressources
    legalNoticesTitle: Mentions légales
    logo:
      alt: Logo Enerfip Gestion
    nav:
      title: Plan du site
    office:
      madrid:
        city: Madrid
        country: España
        district: 28004 Madrid
        flag: drapeau espagnol
        street: Calle Valverde 02
      montpellier:
        city: Montpellier
        country: France
        district: 34 000 Montpellier
        flag: drapeau français
        street: 6 Rue Maguelone
      moreInfo: en savoir plus
    paymentInfoTitle: Quand et comment régler ?
    taxInfoTitle: Quelle fiscalité ?
    socialNetworks:
      title: Réseaux sociaux
    termsAndConditionsTitle: Conditions générales
    usefullLinks:
      legalNotice: Mentions légales
  header: 
    home: Logo Enerfip Gestion
contact:
  iframe: Chargement…
  mainTitle: <span>Nous trouver,</span><br>nous contacter
  meta: 
    description: Contact et adresses de nos bureaux
    title: Contact
  title: Contact
esg:
  approaches:
    prioritiesDescription: Sélection des participations au regard de leur volonté et capacité à générer un impact positif en réponse aux enjeux prioritaires identifiés par Enerfip Gestion.
    priority:
      environment: Environnement
      social: Social
      gouvernance: Gouvernance
      environmentDescription: Approvisionnement durable, efforts constants pour réduire l’empreinte carbone, la gestion des déchets et l’impact de la pollution...
      socialDescription: Inégalités liées au genre, conditions de travail, politique de ressources humaines, plan de développement des performances...
      gouvernanceDescription: Conformité, éthique des affaires, normes comptables, gestions des risques...
    header:
      exclusions: Exclusions sectorielles et normatives
      metrics: Indicateurs de suivi
      priorities: Sélection des participations
  mainTitle: <span>ESG</span><br>& impact
  meta: 
    description: Enerfip Gestion est engagée dans une démarche de promotion des critères ESG depuis sa création.
    title: Charte ESG
  missions:
    description: 'Entreprise à mission, Enerfip Gestion a inscrit sa raison d’être dans ses statuts :'
    item:
      alternatives: "Permettre le développement d'alternatives performantes et bas carbone pour lutter contre les changements climatiques."
      decarbonization: "Décarboner l'épargne et plus globalement les investissements de tous et toutes,"
      technologies: Soutenir les technologies adaptées aux problématiques et enjeux énergétiques spécifiques des territoires,
      transition: Accélérer la transition énergétique par le déploiement d’infrastructures à impact environnemental réduit,
      transparency: Rendre les investissements durables transparents et intelligibles, afin qu’ils puissent être accessibles au plus grand nombre,
    listDescription: 'Dans le cadre de son statut de société à mission, Enerfip Gestion a défini plusieurs objectifs à poursuivre au sein de ses activités :'
    quote: « Contribuer à la lutte contre le changement climatique en accélérant la transition énergétique par le financement d’infrastructures à impact positif et durable pour l’environnement, les territoires et les populations locales »
  partners:
    franceInvest: 'Notre partneaire ESG : France Invest'
    pri: 'Notre partenaire ESG : Principles for Responsible Investment'
  policies:
    banner:
      city: 'Faire en sorte que les villes et les établissements humains soient ouverts à tous, sûrs, résilients et durables.'
      cityTitle: ODD n°11
      commitments:
        energy: "Objectif ESG pour l'énergie"
        industry: "Objectif ESG pour l'industrie"
        city : Objectif ESG pour la ville
        consumption: Objectif ESG pour la consommation
        measures: Objectif ESG pour les mesures
      consumption: Établir des modes de consommation et de production durables.
      consumptionTitle: ODD n°12
      energy: Garantir l’accès de tous à des services énergétiques fiables, durables et modernes, à un coût abordable.
      energyTitle: ODD n°7
      industry: Mettre en place une infrastructure résiliente, promouvoir une industrialisation durable qui profite à tous et encourager l’innovation.
      industryTitle: ODD n°9
      measures: Prendre d’urgence des mesures pour lutter contre les changements climatiques et leurs répercussions.
      measuresTitle: ODD n°13
    description: "Enerfip Gestion s'attache à appliquer une politique d'investissement durable en accord avec les enjeux liés à la transition énergétique et la mitigation du changement climatique."
    item:
      development: Au développement de capacités de production d’énergies renouvelables avec un faible taux d’émission de gaz à effet de serre.
      reduction: À la sobriété énergétique et à la réduction des besoins en énergie, qui passent par le développement de procédés permettant une amélioration de l’efficacité énergétique dans notre économie.
      consumption: À la production et à la consommation responsable (notamment à destination des particuliers, au travers des produits d’épargne verts et les outils de monitoring de la consommation énergétique).
    listDescription: 'Ces enjeux regroupent en leur sein les défis liés :'
  subtitle: Société de gestion spécialisée en infrastructure, private equity et signataire des UN PRI depuis 2022. Enerfip Gestion est engagée dans une démarche de promotion des critères ESG depuis sa création.
  title: Notre charte
home:
  energy:
    card:
      efficiency: Efficacité Énergétique
      hydro: Hydroélectricité
      hydrogen: Hydrogène
      mobility: Mobilité
      solar: Photovoltaïque
      wind: Éolien
    content: Une stratégie d'investissement qui oeuvre en faveur de la transition énergétique
    moreInfoLink: En savoir plus
  expertise:
    title: Notre expertise
  figures:
    item:
      avoidedCarbon:
        figure: +220K
        description: tonnes d'émission de CO2eq évitées
      financedProjects:
        figure: '+300'
        description: projets et sociétés accompagnés
      invested:
        figure: '+300M'
        description: euros investis et gérés
  header: 
    mainTitle: L'investissement<br>transparent et responsable<br><span>dans la transition énergétique</span>
  meta:
    description: Société de gestion spécialisée en infrastructure et private equity, Enerfip Gestion propose des solutions d'investissements qui accompagnent la transition énergétique.
    title: L'investissement dans la transition énergétique
  mission:
    link: Notre stratégie d'investissement
    title: Notre mission
  repartition:
    description: Montants investis et engagés en euros, hors montants levés. Répartition technologique - au 31/12/2022
    energy:
      efficency:
        energy: Efficacité énergétique
        percentage: 7%
      hydroelectricity:
        energy: Hydroélectricité
        percentage: 2%
      innovation:
        energy: Innovation
        percentage: 3%
      solar:
        energy: Photovoltaïque
        percentage: 55%
      wind:
        energy: Éolien
        percentage: 33%
    title: Notre champ d'action
  values:
    item:
      diversified:
        title: Une allocation diversifiée
        description: "Nous avons vocation à investir dans des projets photovoltaïques, éoliens et plus généralement dans tous les secteurs de la transition énergétique, le tout avec une approche diversifiée d'investissements en infrastructures et en corporate."
      dynamic:
        title: Un segment de marché porteur
        description: "Présents sur tous les segments de la transition énergétique, nous nous concentrons sur des opportunités de petite et moyenne taille, un marché largement inexploité aujourd'hui."
      esg:
        title: Une démarche ESG
        description: "Nous accompagnons nos différentes participations dans leur démarche RSE grâce à une équipe dynamique engagée dans l'impact."
    qualiDesk: "Table de travail d'Enerfip Gestion"
    title: Notre proposition de valeur 
legalNotice:
  item:
    title:
      copyright: Droits d'auteur / Copyright
      enerfipGestion: Enerfip Gestion
      hostContact: "Coordonnées de l'hébergeur du site Internet"
      personalData: Données personnelles
      responsabilities: Responsabilités
  link:
    cgu: "Conditions Générales d'Utilisation"
    privacy: "Politique de confidentialité des données personnelles"
    rgpd: "Procédure de protection des données personnelles (RGPD)"
  mainTitle: Mentions<br><span>légales</span>
  meta:
    description: Conditions générales d'utilisation, politique de confidentialité et procédure de protection des données personnelles.
    title: Mentions légales
notFound:
  mainTitle: Erreur<br><span>404</span>
  subtitle: La page que vous cherchez n'existe pas
shared:
  anchors: 
    item:
      esg:
        approach: démarche
        mission: mission
        policy: politique
      team:
        members: Équipe
        values: Nos convictions
        offices: Nos bureaux
  bulletList:
    investment:
      list:
        diversification: Une stratégie investissant à la fois sur des projets d’infrastructures (70%) et sur des projets “corporate” (30%).
        greenfield: Une intervention à n'importe quel stade de la chaîne de valeur, mais visant à privilégier les projets Greenfield (~60%) et investir le plus en amont pour maximiser la création de valeur.
        risk: Focus sur les développeurs ayant un portefeuille de projets en développement important, pour atténuer le risque par le portfolio risk management.
        support: Un accompagnement de l’indépendance énergétique des pays cible au travers des investissements dans des infrastructures d’énergies renouvelables tout en accompagnant les industriels du secteur dans leur stratégie de développement.
    position:
      list:
        balance: "Une répartition équilibrée entre equity et dette, particulièrement adaptée aux petits développeurs: le fonds vise environ 70% de simili-dette et 30% d’equity “corporate”."
        diversification: Une diversification entre l’accompagnement d’actifs en cours de développement et le financement d’actifs d’entreprises en pleine croissance.
        durability: Des solutions flexibles et durables répondant aux enjeux du secteur.
        environment: Une proposition de valeur adaptée aux enjeux environnementaux et sociétaux qui nous entourent.
  contact:
    info:
      item:
        envelope: gestion{'@'}enerfip.eu
        phone: 04 84 80 00 12
      title: Nous contacter
    offices:
      address: 
        es: Calle Valverde 02 28004 Madrid
        fr: 6 Rue Maguelone 34 000 Montpellier
      city:
        es: Madrid
        fr: Montpellier
      title: Nos bureaux
  esgHeader:
    approachDescription: "Et mesure de l'impact"
    approachTitle: Démarche ESG
    missionDescription: Contribuer à la lutte contre le changement climatique
    missionTitle: Entreprise à mission
    policyDescription: "Une politique d'investissement durable"
    policyTitle: Investisseur Responsable
  navbar: 
    contact: Contact
    esg: ESG
    strategy: Stratégie
    team: "L'équipe"
  rowList:
    esgMetrics:
      description: "Suivi des indicateurs d'impact clés choisis et appréciation de l'impact généré à la lumière des objectifs de durabilité préétablis."
      items:
        capacity: Capacité énergétique des projets financés
        co2: Émissions de CO2 évitées par les projets financés
        quantity: Nombre de foyers alimentés par les projets financés
    esgExclusions:
      description: "En plus des secteurs d'activités controversés, Enerfip Gestion s'interdit donc de participer au financement de projets dans les domaines suivants :"
      items:
        coal: Production et distribution de charbon
        fossil: Production d'énergies fossiles
        nuclear: Production et distribution d'énergie nucléaire
  strategyTitle:
    title:
      strategyGoals: Objectifs de durabilité
      strategyInvestment: Stratégie d'investissement
      strategyPosition: Une position unique sur le marché
  tapbar:
    contact: Prendre contact
    menu: Menu
    panel:
      close: Fermer le menu
strategy:
  header:
    title: "<span>Stratégie</span><br>d'investissement"
  investment:
    energySchema: Stratégie d'investissement dans les énergies
    fundStrategy: Stratégie d'investissement du fond d'investissement
  meta:
    description: Nous mettons en œuvre une stratégie d’investissement à impact diversifiée à la fois dans la typologie des instruments utilisés mais aussi dans les acteurs et les technologies financés.
    title: Stratégie d'investissement
  position:
    label:
      major: Investissement majeur
      minor: Investissement mineur
      offices: Nos bureaux
  sidebar:
    contactUs: Contactez nous
    link: Prendre contact
team:
  mainTitle: <span>Une équipe</span><br>un état d'esprit
  members:
    display:
      picture: "{name} de l'équipe Enerfip Gestion"
  meta:
    description: Les membres de notre équipe contribuent à faire d'Enerfip le leader français et européen de l'investissement écoresponsable.
    title: Notre équipe
  subtitle: "C'est grâce à l'expertise, l'engagement et l'énergie des 27 membres de notre équipe qu'Enerfip est devenue aujourd'hui leader français et européen de l'investissement écoresponsable fédérant plus de 40.000 investisseurs. Notre volonté est de démocratiser l’investissement non-coté dans la transition énergétique."
  title: Notre équipe
  values:
    item:
      business-time: La durabilité au service de la rentabilité avec accompagnement
      lock-open: Transparence
      people-group: Une démarche accessible à tous
      radar: Flexibilité et agilité
    title: Nos convictions

<script setup>
  import TeamValuesItem from '@/components/team/values/TeamValuesItem.vue';

  const values = ['people-group', 'business-time', 'radar', 'lock-open']
</script>

<template>
  <section class='team-values'>
    <div class='arc-title team-values-title'>
      <h2> {{ $t('team.values.title') }}</h2>
    </div>
    <div class='team-values-list'>
      <TeamValuesItem 
        v-for='value in values' 
        :key='value' 
        :value='value'
      />
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .team-values {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-lg;
 
    .team-values-title{
      font-weight: 300;
      color: $dark;
      text-align: center;
      align-items: center;
    }
    .team-values-list{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    grid-gap: $spacer-md;
    }
  }
</style>

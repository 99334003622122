<template>
  <div class='usefull-links'>
    <router-link
      :to="{name: 'legalNotice'}"
      class='legal-notice-link'
      data-test-id='app-footer-usefull-links-legal-notice'
    >
      {{ $t('app.footer.usefullLinks.legalNotice') }}
    </router-link>
    <a 
      href='https://www.linkedin.com/company/enerfip-gestion/about/'
      data-test-id='app-footer-usefull-links-linkedin'
    >
      <FontAwesomeIcon
        class='social-icon'
        :icon="['fab', 'linkedin']"
      />
    </a>
  </div>
</template>

<style scoped lang='scss'>
  .usefull-links {
    display: flex;
    justify-content: space-between;
    margin: 0 5% 0 5%;
    .legal-notice-link {
      color: $light;
      text-decoration: none;
    }
    .social-icon {
      color: $light;
    }
  }
</style>

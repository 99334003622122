<script setup>
  import { ref, onMounted } from 'vue';

  import LegalNoticeItemTitle from '@/components/legal-notice/item/LegalNoticeItemTitle.vue';

  import { liquidLoader } from '@/mixins/liquid-loader.js';

  const fileContent = ref('');

  const props = defineProps({
    fileName: { type: String, required: true },
    filePath: { type: String, required: true },
  });

  onMounted(() => {
    liquidLoader(fileContent, props.filePath);
  });
</script>

<template>
  <div class='legal-notice-item'>
    <LegalNoticeItemTitle 
      :title='fileName' 
      class='arc-title item-title' 
    />
    <p 
      data-test-id='legal-notice-md-file' 
      v-html='$sanitize(fileContent)' 
    />
  </div>
</template>

<style scoped lang='scss'>
.legal-notice-item {
  margin-bottom: 50px;
  .item-title {
    text-align: center;
    width: 100%;
  }
  p{
    font-size: 16px;
    text-align: justify;
  }
}
</style>
<script setup>
  import HomeEnergy from '@/components/home/HomeEnergy.vue';
  import HomeExpertise from '@/components/home/HomeExpertise.vue';
  import HomeFigures from '@/components/home/HomeFigures.vue';
  import HomeHeader from '@/components/home/HomeHeader.vue';
  import HomeMission from '@/components/home/HomeMission.vue';
  import HomeRepartition from '@/components/home/HomeRepartition.vue';
  import HomeValues from '@/components/home/HomeValues.vue';
  
  import useMeta from '@/composables/useMeta';

  useMeta('home');
</script>

<template>
  <div class='home'>
    <HomeHeader class='responsive-container' />
    <HomeMission class='responsive-container' />
    <HomeFigures class='responsive-container' />
    <HomeRepartition class='responsive-container' />
    <HomeValues class='responsive-container' />
    <HomeExpertise class='responsive-container' />
    <HomeEnergy />
  </div>
</template>

<style scoped lang='scss'>
</style>

<script setup>
  import { useI18n } from 'vue-i18n';

  defineProps({
    page: { type: String, default: '' },
  })

  const { te } = useI18n()
</script>

<template>
  <section class='responsive-container section-title'>
    <p
      v-if='te(`${page}.title`)'
      class='head'
    >
      {{ $t(`${page}.title`) }}
    </p>
    <h1 
      data-test-id='shared-main-title'
      v-html='$sanitize($t(`${page}.mainTitle`))'
    />
    <p 
      v-if='te(`${page}.subtitle`)'
      data-test-id='shared-main-title-subtitle'
    >
      {{ $t(`${page}.subtitle`) }}
    </p>
  </section>
</template>

<style lang='scss'>
  .section-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-md;
    .head {
      text-transform: uppercase;
      color: $primary;
      font-size: $typer-lg;
      font-weight: 500;
    }
    h1 {
      -webkit-text-fill-color: $dark;
      -webkit-text-stroke-width: 0px;
      font-weight: 500;
      color: $dark;
      span {
        -webkit-text-fill-color: transparent;
        font-weight: 500;
        color: $dark;
      }
    }
    p {
      font-size: $typer-lg;
      font-weight: 500;
      color: $secondary;
      width: 100%;
    }
  }
</style>
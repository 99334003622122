<script setup>
</script>

<template>
  <header class='strategy-header'>
    <h1 
      data-test-id='strategy-header-title' 
      class='section-header-title'
      v-html="$sanitize($t('strategy.header.title'))"
    />
  </header>
</template>

<style lang='scss'>
  .strategy-header {
    width: 100%;
    background:
      linear-gradient(0deg,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),
      url(@/../app/assets/images/img_strategy/header-background.jpg);
    background-size: cover;
    color: white;
    border-radius: 18px;
    padding: $spacer-lg;
    .section-header-title {
      -webkit-text-fill-color: white;
      -webkit-text-stroke-width: 0px;
      font-weight: 300;
      color: white;
      span {
        -webkit-text-fill-color: transparent;
        font-weight: 500;
      }
    }
  }
</style>

<script setup>
  import flagFr from '@/../app/assets/images/img_footer/flag-fr.svg'
  import flagEs from '@/../app/assets/images/img_footer/flag-es.svg'

  defineProps({
    office: { type: String, default: '' }
  })

  const offices = {
    'montpellier': flagFr,
    'madrid': flagEs
  }
</script>

<template>
  <div class='app-footer-office'>
    <div class='card-title'>
      <h5 data-test-id='app-footer-office-city'>
        {{ $t(`app.footer.office.${office}.city`) }}
      </h5>
      <p>
        {{ $t(`app.footer.office.${office}.country`) }}
        <img
          :src='offices[office]'
          :alt='$t(`app.footer.office.${office}.flag`)'
          data-test-id='app-footer-office-flag'
        >
      </p>
    </div>
    <p class='card-text'>
      {{ $t(`app.footer.office.${office}.street`) }}<br>
      {{ $t(`app.footer.office.${office}.district`) }}
    </p>
    <router-link
      :to="{ name: 'contact' }"
      class='more-info-link'
    >
      <div>
        <FontAwesomeIcon
          class='info-icon'
          :icon="['fal', 'memo-circle-info']"
        />
        {{ $t(`app.footer.office.moreInfo`).toUpperCase() }}
      </div>
      <FontAwesomeIcon
        class='info-icon-end'
        :icon="['fal', 'arrow-right']"
      />
    </router-link>
  </div>
</template>

<style scoped lang='scss'>
  .app-footer-office{
    background-color: white;
    border-radius: 9px;
    padding: $spacer-s;
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-s;

    .card-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        font-size: 18px;
        font-weight: 500;
        color: $primary;
      }
      p {
        display: flex;
        font-size: 9px;
        font-weight: 500;
        color: $icons;
        text-transform: uppercase;
      }
      img{
        margin-left: 6px;
      }
    }
    .card-text {
      color: $secondary;
      font-size: 12px;
      font-weight: 700;
    }
    .more-info-link {
      font-size: 12px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      color: $secondary;
      background: none;
      div{
        display: flex;
        align-items: center;
        grid-gap: $spacer-s;
      }
      .info-icon, .info-icon-end{
        height: 24px;
        color: $dark;
        padding: 6px;
      }

    }
  }
</style>

<script setup>
  import SharedBulletItem from '@/components/shared/SharedBulletItem.vue';
  import SharedStrategyTitle from '@/components/shared/SharedStrategyTitle.vue';
  import StrategyPositionLabel from '@/components/strategy/position/StrategyPositionLabel.vue';

  const labels = [
    { color: '#152F52', slug: 'major' },
    { color: '#445975', slug: 'minor' },
    { color: '#C9A264', slug: 'offices' },
  ]

  const items = [ 'diversification', 'balance', 'environment', 'durability' ];
</script>

<template>
  <section class='strategy-investment'>
    <SharedStrategyTitle
      icon='trophy-star'
      slug='strategyPosition'
    />
    <div class='strategy-section'>
      <div class='europe-map'>
        <StrategyPositionLabel
          v-for='label in labels'
          :key='label.slug'
          :color='label.color'
          :slug='label.slug'
        />
      </div>
      <div class='position-list'>
        <div class='shared-bullet-list-group'>
          <SharedBulletItem
            v-for='item in items'
            :key='item'
            :item='item'
            component='position'
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .strategy-investment {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-md;
    .europe-map {
      background: url(@/../app/assets/images/img_strategy/europe-map2.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      height: 500px;
      margin-top: calc(0px - 8px - 1.5625vw);
      margin-right: calc(0px - 8px - 1.5625vw);
      padding-bottom: 25px;
      padding-top: 25px;
    }
    .position-list {
      color: $secondary;
    }
  }
</style>
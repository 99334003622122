<script setup>
  import EsgApproaches from '@/components/esg/EsgApproaches.vue';
  import EsgMissions from '@/components/esg/EsgMissions.vue';
  import EsgPartners from '@/components/esg/EsgPartners.vue';
  import EsgPolicies from '@/components/esg/EsgPolicies.vue';
  import SharedAnchors from '@/components/shared/SharedAnchors.vue';
  import SharedMainTitle from '@/components/shared/SharedMainTitle.vue';

  import useMeta from '@/composables/useMeta';

  useMeta('esg');
  
  const anchors = [
    { slug: 'mission', active: true },
    { slug: 'policy', active: false },
    { slug: 'approach', active: false }
  ];
</script>

<template>
  <div class='responsive-container content-spacer'>
    <SharedMainTitle page='esg' />
    <SharedAnchors
      :items='anchors'
      page='esg'
    />
    <EsgMissions id='esg-mission-section' />
    <EsgPolicies id='esg-policy-section' />
    <EsgApproaches id='esg-approach-section' />
    <EsgPartners />
  </div>
</template>

<style scoped lang='scss'>
</style>

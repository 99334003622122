<script setup>
import SharedTapbarPanel from '@/components/shared/tapbar/SharedTapbarPanel.vue';
import { ref } from 'vue';

  const visibleClass = ref('')

  function openPanel(){
    visibleClass.value = 'panel--is-visible'
  }
  function closePanel(){
    visibleClass.value = ''
  }
</script>

<template>
  <SharedTapbarPanel 
    :class='visibleClass'
    @emitted-visible-class='closePanel'
  />

  <div
    id='tapbar'
    class='tapbar'
  >
    <div class='tapbar-wrapper'>
      <div class='tapbar-nav'>
        <router-link
          :to="{ name: 'contact' }"
          class='cta-contact-link'
        >
          <a class='cta-outline-primary'>
            <div 
              class='cta-label'
              data-test-id='tapbar-contact'
            >
              {{ $t('shared.tapbar.contact') }}
            </div>
            <div class='cta-icon-wrapper'>
              <FontAwesomeIcon
                :icon="['fal', `circle-envelope`]"
              />
            </div> 
          </a>
        </router-link>
        <a
          class='tapbar-nav-item js-panel-trigger'
          href='#'
          data-panel='main'
          data-test-id='open-panel-button'
          @click='openPanel'
        >
          <div class='tapbar-nav-item-icon'>
            <FontAwesomeIcon
              :icon="['fal', `bars`]"
            />
          </div>
          <div class='tapbar-nav-item-label'>
            {{ $t('shared.tapbar.menu') }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang='scss'>
.panel--is-visible {
  visibility: visible;
  -webkit-transition: visibility 0s 0s;
  transition: visibility 0s 0s;
  &::after {
    background: rgba(0, 0, 0, 0.6);
    -webkit-transition: background 0.3s 0s;
    transition: background 0.3s 0s;
  }
}
.tapbar {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: $spacer-md;
  z-index: 10;
  align-items: center;
  @media(min-width: 1024px){
    display: none;
  }
  
  &-wrapper {
    background: white;
    padding: $spacer-md;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 $spacer-md rgba(0, 0, 0, 0.2);
    @media (min-width: 1024px) {
      display: none;
    }
  }
  &-nav {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
    justify-content: space-around;
    
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $primary;
      text-decoration: none;
      &-icon {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0.5rem;
        border-radius: 10rem;
        transition: all ease 0.5s;
      }
      &-label {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
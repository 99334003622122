<script setup>
  import { ref } from 'vue';
  import SharedContactOfficesItem from '@/components/shared/contact/offices/SharedContactOfficesItem.vue';

  const hover = ref(false) 
  const emit = defineEmits(['hover'])

  function onHover(newHover) {
    newHover = !newHover;
    hover.value = newHover;
    emit('hover', newHover)
  }

  const countries = ['fr', 'es']
</script>

<template>
  <div class='shared-contact-offices'>
    <div class='arc-title shared-contact-offices-title'>
      <h2 data-test-id='shared-contact-offices-title'>
        {{ $t('shared.contact.offices.title') }}
      </h2>
    </div>
    <div class='shared-contact-office-content'>
      <SharedContactOfficesItem 
        v-for='country in countries' 
        :key='country'
        :country='country'
        @mouseover="country == 'es' ? onHover(hover) : null"
        @mouseout="country == 'es' ? onHover(hover) : null"
      />
      <div
        data-test-id='shared-contact-offices-map'
        class='maps'
        :class="hover ? 'madrid-map' : 'mtp-map'"
      />
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .shared-contact-offices {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-lg;

    .shared-contact-offices-title{
      align-items: center;
    }
    .maps {
      border-radius: 18px;
      filter: drop-shadow(0px 10px 20px rgba(41, 41, 42, 0.07));
      min-height: 240px;
      background-size: cover;
      background-position: center;
    }
    .mtp-map {
      background-image: url('@/../app/assets/images/img_office/montpellier-map.jpg')
    }
    .madrid-map {
      background-image: url('@/../app/assets/images/img_office/madrid-map.jpg')
    }
    .shared-contact-office-content{
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-md;
    }
    h2 {
      text-align: center;
      color: $dark;
    }
  }
</style>
<script setup>
  import AppFooterNavLink from './nav/AppFooterNavLink.vue';

  const routeNames = ['strategy', 'team', 'esg', 'contact']
</script>

<template>
  <h6 
    class='nav-title'
    data-test-id='app-footer-nav-title'
  >
    {{ $t('app.footer.nav.title') }}
  </h6>
  <div class='app-footer-nav'>
    <AppFooterNavLink
      v-for='routeName in routeNames'
      :key='routeName'
      :route-name='routeName'
    />
  </div>
</template>

<style scoped lang='scss'>
    .nav-title { 
      color: white;
      font-size: $typer-md;
      font-weight: 500;
    }
  .app-footer-nav {
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-s;
  }
</style>

<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';

  import AppHeader from '@/components/app/AppHeader.vue';
  import AppFooter from '@/components/app/AppFooter.vue';

  const route = useRoute()
  const isNotHome = computed(() => route.name != 'home')
</script>

<template>
  <AppHeader v-if='isNotHome' />
  <router-view />
  <AppFooter />
</template>

<style lang='scss'>
  @import "@/stylesheets/index";
</style>

<script setup>
  import HomeFiguresItem from '@/components/home/figures/HomeFiguresItem.vue';

  const figures = [
    { slug: 'financedProjects', icon: 'house-day' },
    { slug: 'avoidedCarbon', icon: 'smog'},
    { slug: 'invested', icon: 'coins' }
  ];
</script>

<template>
  <section class='home-figures'>
    <HomeFiguresItem 
      v-for='figure in figures' 
      :key='figure.slug'
      :icon='figure.icon'
      :slug='figure.slug'
    />
  </section>
</template>

<style scoped lang='scss'>
  .home-figures {
    display: flex;
    flex-direction: column;
    grid-gap:$spacer-md;
    @media(min-width: 1024px){
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between;
    }
  }
</style>

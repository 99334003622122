<script setup>
  const routeNames = ['strategy', 'team', 'esg', 'contact']

  defineProps({
    navLinkColor: { type: String, default: 'white' }
  })
</script>

<template>
  <div class='shared-nav-bar'>
    <router-link
      v-for='routeName in routeNames'
      :key='routeName'
      :to='{ name: routeName }'
      class='shared-nav-link'
      data-test-id='shared-nav-bar-link'
    >
      {{ $t(`shared.navbar.${routeName}`) }}
    </router-link>
  </div>
</template>

<style scoped lang='scss'>
  .shared-nav-bar {
    display: none;
    @media(min-width: 1024px){
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    }
    .shared-nav-link {
      color: v-bind(navLinkColor);
      font-family: var(--bs-body-font-family);
      margin: 15px;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
</style>

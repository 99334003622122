<script setup>
  import { liquidLoader } from '@/mixins/liquid-loader.js';
  import { ref, onMounted } from 'vue';

  import EsgPoliciesBanner from '@/components/esg/policies/EsgPoliciesBanner.vue';
  import EsgPoliciesItem from '@/components/esg/policies/EsgPoliciesItem.vue';
  import SharedEsgHeader from '@/components/shared/SharedEsgHeader.vue';

  const policyDescription = ref('');

  const challenges = ['development', 'reduction', 'consumption'];
  const commitments = ['energy','industry','city','consumption','measures'];

  onMounted(() => {
    liquidLoader(policyDescription, 'esg/policy-description');
  });
</script>

<template>
  <section class='esg-policies'>
    <SharedEsgHeader section='policy' />
    <div class='esg-section-content'>
      <p class='policies-description'>
        {{ $t('esg.policies.description') }}
      </p>
      <p data-test-id='policy-list'>
        {{ $t('esg.policies.listDescription') }}
      </p>
      <ul class='policy-list'>
        <EsgPoliciesItem
          v-for='(challenge, index) in challenges'
          :key='challenge'
          :challenge='challenge'
          :index='index + 1'
        />
      </ul>
      <div
        class='shared-content-wrapper'
        v-html='$sanitize(policyDescription)'
      />
      <EsgPoliciesBanner
        v-for='commitment in commitments'
        :key='commitment'
        :commitment='commitment'
      />
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .esg-policies {
    ul.policy-list {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-md;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }
</style>

<script setup>
  import energy from '@/../app/assets/images/img_esg/energy.svg'
  import city from '@/../app/assets/images/img_esg/city.svg'
  import consumption from '@/../app/assets/images/img_esg/consumption.svg'
  import industry from '@/../app/assets/images/img_esg/industry.svg'
  import measures from '@/../app/assets/images/img_esg/measures.svg'

  defineProps({
    commitment: { type: String, required: true, default: '' }
  })

  const commitments = {
    'energy': energy,
    'industry': industry,
    'city': city,
    'consumption': consumption,
    'measures': measures
  }
  
  const oddCommitments = ['energy', 'city', 'measures']
  function directionClass(commitment) {
    if (oddCommitments.includes(commitment)) {
      return 'reverse-direction'
    }
  }
</script>

<template>
  <div
    class='esg-policies-banner'
    :class='directionClass(commitment)'
    data-test-id='esg-policies-banner'
  >
    <p>
      <span>{{ $t(`esg.policies.banner.${commitment}Title`) }}</span>
      <br>
      {{ $t(`esg.policies.banner.${commitment}`) }}
    </p>
    <img
      :src='commitments[commitment]'
      :alt='$t(`esg.policies.banner.commitments.${commitment}`)'
      data-test-id='esg-odd-banner-commitment'
    >
  </div>
</template>

<style scoped lang='scss'>
  .esg-policies-banner{
    align-items: center;
    background: white;
    background-size: contain; 
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 712px;
    overflow: hidden;
    border-radius: 18px;
    margin-bottom: 24px;
    filter: drop-shadow(0px 0px 36px rgba(0, 0, 0, 0.06));
    p {
      margin: 18px 36px;
      span {
        font-family: $headings-font-family;
        font-weight: 600;
      }
    }
    img {
      width: 150px;
    }
  }
  .reverse-direction{
    flex-direction: row-reverse;
  }
</style>

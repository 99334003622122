import {
  faArrowRight,
  faBadgeCheck,
  faBars,
  faBicycle,
  faBolt,
  faBridgeWater,
  faBuildingFlag,
  faBullseyeArrow,
  faCircle,
  faCircle1,
  faCircle2, 
  faCircle3,
  faCircleCalendar,
  faCircleEnvelope,
  faCirclePhone,
  faCity,
  faCoin,
  faDashboard,
  faDroplet,
  faEarthEurope,
  faFileLines,
  faFilePdf,
  faFlask,
  faGasPump,
  faHands,
  faHouseBuilding,
  faLeaf,
  faLightbulb,
  faMapMarked,
  faMemoCircleInfo,
  faPickaxe,
  faPiggyBank,
  faRadiation,
  faRefresh,
  faSeedling,
  faSolarPanel,
  faUsers,
  faWindTurbine,
  faTachometer,
  faTemperatureHigh,
  faTrophyStar,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faCircleUser,
  faFlag,
  faInfo,
  faQuestion
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowUpRightDots,
  faBusinessTime,
  faChartNetwork,
  faCoins,
  faHandHoldingSeedling,
  faHouseDay,
  faLockOpen,
  faPeopleGroup,
  faRadar,
  faSmog
} from "@fortawesome/pro-thin-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faArrowRight,
  faArrowUpRightDots,
  faBadgeCheck,
  faBars,
  faBicycle,
  faBolt,
  faBridgeWater,
  faBuildingFlag,
  faBullseyeArrow,
  faBusinessTime,
  faChartNetwork,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircleCalendar,
  faCircleEnvelope,
  faCirclePhone,
  faCircleUser,
  faCity,
  faCoin,
  faCoins,
  faDashboard,
  faDroplet,
  faEarthEurope,
  faFileLines,
  faFilePdf,
  faFacebook,
  faFlag,
  faFlask,
  faGasPump,
  faHandHoldingSeedling,
  faHands,
  faHouseBuilding,
  faHouseDay,
  faInfo,
  faLeaf,
  faLightbulb,
  faLinkedin,
  faLockOpen,
  faMapMarked,
  faMemoCircleInfo,
  faPeopleGroup,
  faPickaxe,
  faPiggyBank,
  faRadiation,
  faQuestion,
  faRadar,
  faRefresh,
  faSeedling,
  faSmog,
  faSolarPanel,
  faTachometer,
  faTemperatureHigh,
  faTrophyStar,
  faTwitter,
  faUsers,
  faWindTurbine,
  faXmark,
  faYoutube
)

export { library };

import { i18n } from '@/i18n.js';
import { useHead } from '@unhead/vue';

export default function (page) {
  const { t } = i18n.global;
  const metaData = {
    title: 'Enerfip Gestion - ' + t(`${page}.meta.title`),
    meta: [
      {
        name: 'description',
        content: t(`${page}.meta.description`),
      },
      {
        property: 'og:title',
        content: 'Enerfip Gestion - ' + t(`${page}.meta.title`),
      },
      {
        property: 'og:description',
        content: t(`${page}.meta.description`),
      },
      {
        property: 'og:image',
        content: `https://${window.location.host}/social_media_cover.jpg`,
      },
    ],
  };
  const head = useHead(metaData);

  return {
    head
  }
}

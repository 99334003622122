<script setup>
  import HomeEnergyCard from '@/components/home/energy/HomeEnergyCard.vue';

  const energies = [
    { icon: 'solar-panel', slug: 'solar' },
    { icon: 'wind-turbine', slug: 'wind' },
    { icon: 'bridge-water', slug: 'hydro' },
    { icon: 'tachometer', slug: 'efficiency' },
    { icon: 'bicycle', slug: 'mobility' },
    { icon: 'droplet', slug: 'hydrogen' }
  ]
</script>

<template>
  <section class='home-energy'>
    <div class='energy-description'>
      <p class='description-content'>
        {{ $t(`home.energy.content`) }}
      </p>
      <router-link
        :to="{name: 'strategy' }"
        class='description-link'
      >
        {{ $t(`home.energy.moreInfoLink`) }}
        <FontAwesomeIcon
          class='description-link-icon'
          :icon="['fal', 'fa-arrow-right']"
        />
      </router-link>
    </div>
    <div class='energy-cards'>
      <HomeEnergyCard 
        v-for='energy in energies'
        :key='energy'
        class='energy-card'
        :icon='energy.icon'
        :slug='energy.slug'
      />
    </div>
  </section>
</template>

<style scoped lang='scss'>
 .home-energy{
    color: white;
    display: flex;
    flex-direction: column;
    @media(min-width: 1024px){
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
    .energy-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      background-color: $dark;
      padding: $spacer-xl;
      grid-gap: $spacer-md;
      @media(min-width: 1024px){
      border-radius: 0px 36px 36px 0px;
      }
      .description-content {
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: $typer-lg;
        
      }
      .description-link {
        border-bottom: 1px solid white;
        color: white;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 6px;
        text-decoration: none;
        text-transform: uppercase;
        .description-link-icon {
          margin-left: 30px;
        }
      }
    }
    .energy-cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $spacer-md;
      box-sizing: border-box;
      padding: calc(#{$spacer-md}*1.5) $spacer-md;
      @media(min-width: 1024px){
        display: flex;
        overflow-x: scroll;
      }
      flex: 1;
    }
  }
</style>

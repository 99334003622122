<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    active: { type: Boolean, default: false },
    item: { type: String, required: true },
    page: { type: String, required: true }
  })

  const active = computed(() => {
    return props.active ? 'active' : '';
  })
</script>

<template>
  <li class='shared-anchors-item'>
    <a
      class='anchor-link'
      :class='active'
      aria-current='page'
      :href='`#${page}-${item}-section`'
      data-test-id='shared-anchors-item-link'
    >
      {{ $t(`shared.anchors.item.${page}.${item}`) }}
    </a>
  </li>
</template>

<style scope lang='scss'>
  .shared-anchors-item{
    .anchor-link{
      color: $primary;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 50px;
      padding: 6px 12px 6px 12px;
      text-decoration: none;
      &.active{
        color: white;
        background-color: $dark;
      }
    }
  }
</style>
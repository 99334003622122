<script setup>
  import { computed, ref } from 'vue';
  import { liquidLoader } from '@/mixins/liquid-loader.js';
  import { useTeamMemberStore } from '@/stores/team-member';

  const memberDescription = ref('');

  const teamMemberStore = useTeamMemberStore();
  const teamMemberToDisplay = computed(() => {
    loadDescription();
    return teamMemberStore.getActiveTeamMember();
  });

  function loadDescription() {
    liquidLoader(
      memberDescription,
      `stores/team-member/description-${teamMemberStore.getActiveTeamMember().descriptionKey}`
    );
  }
</script>

<template>
  <section class='team-members-display'>
    <div class='team-content'>
      <p
        class='name'
        data-test-id='name-to-display'
      >
        {{ teamMemberToDisplay.name }}
      </p>
      <p class='job-position'>
        {{ teamMemberToDisplay.title }}
      </p>
      <p
        class='citation'
        v-html='$sanitize(memberDescription)'
      />
      <div class='contact-section'>
        <a :href='`mailto:${teamMemberToDisplay.mail}`'>
          <FontAwesomeIcon
            class='contact-icon'
            :icon="['fal', 'circle-envelope']"
          />
        </a>
        <a :href='teamMemberToDisplay.linkedin'>
          <FontAwesomeIcon
            class='social-icon'
            :icon="['fab', 'linkedin']"
          />
        </a>
      </div>
    </div>
    <div class='team-members-picture'>
      <img
        :src='teamMemberToDisplay.picture'
        :alt="$t('team.members.display.picture', {name: teamMemberToDisplay.name})"
      >
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .team-members-display {
    display: flex;
    align-items: center;
    text-align: center;
    @media (min-width: 1024px){
      display: grid;
      grid-template-columns: 4fr 3fr;
      padding-right: 2rem;
      text-align: left;
    }

    .team-members-picture{
      display: none;
      @media (min-width: 1024px){
        display: flex;
        img {
          width: 100%;
        }
      }
    }
    .team-content {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-s;
      .company {
        color: $primary;
        font-family: $headings-font-family;
        font-size: 18px;
        font-weight: 300;
      }
      .job-position {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: $secondary;
      }
      .name {
        font-family: $headings-font-family;
        font-size: 36px;
        font-weight: 300;
        color: $dark;
      }
      .citation {
        font-size: 18px;
        font-weight: 300;
        color: $secondary;
        margin-bottom: 18px;
        text-align: justify;
      }
      .contact-section {
        display: flex;
        grid-gap:$spacer-s;
        justify-content: center;
        @media (min-width: 1024px){
        justify-content: left;
        }
        .contact-icon {
          color: white;
          border-radius: 200px;
          width: 32px;
          height: 32px;
          background-color: $icons;
        }
        .social-icon {
          color: $icons;
          border-radius: 200px;
          width: 32px;
          height: 32px;
          background: rgba(210, 189, 149, 20%)
        }
      }
    }
  }
</style>

<script setup>
  import { computed } from 'vue';

  const vitePublicFilesHost = import.meta.env.VITE_PUBLIC_FILES_HOST;

  const props = defineProps({
    type: { type: String, default: '' },
  })

  const legalNoticeUri = computed(() => {
    return `${vitePublicFilesHost}/legal-notice-${props.type}.pdf`;
  })
</script>

<template>
  <a
    :href='legalNoticeUri'
    class='legal-notice-link'
    data-test-id='legal-notice-link'
    target='_blank'
  >
    <FontAwesomeIcon
      class='doc-icon'
      :icon="['fal', 'file-pdf']"
    />
    {{ $t(`legalNotice.link.${type}`) }}
  </a>
</template>

<style scoped lang='scss'>
  .legal-notice-link {
    display: block;
    border: 1px solid;
    max-width: 500px;
    text-decoration: none;
    background: $primary;
    color: white;
    border-radius: 9px;
    margin: 10px auto;
    padding: 10px;

    .doc-icon {
      height: 20px;
      padding: auto;
    }
    &:hover {
      background: none;
      color: $primary;
    }
  }
</style>

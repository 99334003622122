<script setup>
  import SharedContact from '@/components/shared/SharedContact.vue';
  import SharedMainTitle from '@/components/shared/SharedMainTitle.vue'

  import useMeta from '@/composables/useMeta';

  useMeta('contact');
</script>

<template>
  <div class='page-contact content-spacer'>
    <div class='page-contact-wrapper responsive-container'>
      <SharedMainTitle page='contact' />
      <SharedContact />
      <p class='google-form'>
        <iframe
          data-test-id='contact-iframe' 
          src='https://docs.google.com/forms/d/e/1FAIpQLSfGjAU63b_iPHC0Q5nSpYE_dYj42PwjVpT_28x0sK49hxTwfg/viewform?embedded=true'
          width='100%'
          height='1600'
          frameborder='0'
          marginheight='0'
          marginwidth='0'
        >{{ $t('contact.iframe') }}</iframe>
      </p>
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .page-contact{
    &-wrapper{
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-xl;
    }
    .google-form {
      text-align: center;
    }
  }
</style>
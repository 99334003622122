<script setup>
  import HomeRepartitionEnergy from '@/components/home/repartition/HomeRepartitionEnergy.vue';

  const energies = [
    { color: '#AD9D63', slug: 'solar', icon: 'solar-panel' },
    { color: '#A8B4AE', slug: 'wind', icon: 'wind-turbine' },
    { color: '#945734', slug: 'efficency', icon: 'tachometer' },
    { color: '#744A7E', slug: 'innovation', icon: 'lightbulb' },
    { color: '#346C94', slug: 'hydroelectricity', icon: 'bridge-water' }
  ]
</script> 
<template>
  <section class='home-repartition'>
    <div class='repartition-text'>
      <div class='arc-title repartition-title'>
        <h2>{{ $t('home.repartition.title') }}</h2>
      </div>
      <p class='description'>
        {{ $t('home.repartition.description') }}
      </p>
    </div>
    <div class='repartition-energies'>
      <HomeRepartitionEnergy
        v-for='energy in energies'
        :key='energy.slug'
        :color='energy.color'
        :icon='energy.icon'
        :slug='energy.slug'
      />
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .home-repartition {
    background-image: url('@/../app/assets/images/img_home/europe-map.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    @media(min-width: 1024px) {
      flex-direction:row;
    }
    grid-gap:$spacer-md;
    .repartition-text {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-lg;
      text-align: center;
      @media(min-width: 1024px){
      text-align: left;
    }
      .description {
        font-size: $typer-lg;
        font-weight: 500;
        color: $secondary;
      }
    }
    .repartition-energies {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-md;
    }
  }
</style>

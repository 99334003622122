<script setup>
  import SharedAnchors from '@/components/shared/SharedAnchors.vue';
  import SharedContact from '@/components/shared/SharedContact.vue';
  import SharedMainTitle from '@/components/shared/SharedMainTitle.vue'
  import TeamMembers from '@/components/team/TeamMembers.vue';
  import TeamValues from '@/components/team/TeamValues.vue';
  
  import useMeta from '@/composables/useMeta';

  useMeta('team');

  const anchors = [
    { slug: 'members', active: true },
    { slug: 'values', active: false },
    { slug: 'offices', active: false }
  ]
</script>

<template>
  <div class='responsive-container content-spacer'>
    <SharedMainTitle page='team' />
    <SharedAnchors 
      :items='anchors' 
      page='team'
    />
    <TeamMembers id='team-members-section' />
    <TeamValues id='team-values-section' />
    <SharedContact id='team-offices-section' />
  </div>
</template>

<style scoped lang='scss'>
</style>

<script setup>
  import { liquidLoader } from '@/mixins/liquid-loader.js';
  import { ref, onMounted } from 'vue';

  const description = ref('');

  onMounted(() => {
    liquidLoader(description, 'home/expertise/description');
  });
</script>

<template>
  <section class='home-expertise'>
    <div class='arc-title expertise-title'>
      <h2 data-test-id='home-expertise-link'>
        {{ $t('home.expertise.title') }}
      </h2>
    </div>
    <p
      class='description'
      v-html='$sanitize(description)'
    />
  </section>
</template>

<style scoped lang='scss'>
  .home-expertise {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-lg;
    .description {
      font-size: $typer-lg;
      font-weight: 500;
      color: $secondary;
      width: 100%;
      @media(min-width: 1024px){
        width: 80%;
      }
    }
  }
</style>

<script setup>
  const props = defineProps({
    challenge: {
      type: String,
      required: true,
      default: ''
    },
    index: {
      type: Number,
      required: true,
      default: 0
    }
  })

  function indexToString() {
    return (props.index).toString()
  }
</script>

<template>
  <li>
    <div class='arc-title'>
      <p
        class='stake-number'
        data-test-id='esg-policies-item-index'
      >
        {{ indexToString() }}
      </p>
    </div>
    {{ $t(`esg.policies.item.${challenge}`) }}
  </li>
</template>

<style scoped lang='scss'>
  li {
    display: flex;
    align-items: center;
    padding: 0;
    grid-gap: $spacer-md;
    color: $dark;    
    margin: 0;
    @media(min-width: 1024px){
      padding: 0 $spacer-md;
    }
    .icon {
      color: white;
      stroke: black;
      stroke-width: 12px;
    }
    .stake-number {
      text-align: center;
    }
    p {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1.5px;
      font-weight: 500;
      font-size: 42px;
      font-family: $headings-font-family;
      margin: 0;
    }
  }
</style>


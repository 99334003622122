import { Liquid } from 'liquidjs';
import { Converter } from 'showdown';
import { contentMapper } from './content-mapper.js';

export async function liquidLoader(vueData, filePath, params = {}) {
  const converter = new Converter();
  const engine = new Liquid();
  const locale = 'fr';
  const file = contentMapper[locale][filePath];

  await engine.renderFile(file, params).then(function(text) {
    vueData.value = converter.makeHtml(text);
  }.bind(vueData));
}

<script setup>
  import EsgMissionsItem from './missions/EsgMissionsItem.vue';
  import SharedEsgHeader from '@/components/shared/SharedEsgHeader.vue';

  const missions = [
    { mission: 'transition', icon: 'refresh' },
    { mission: 'decarbonization', icon: 'leaf'},
    { mission: 'transparency', icon: 'hands' },
    { mission: 'technologies', icon: 'map-marked' },
    { mission: 'alternatives', icon: 'dashboard' }
  ];
</script>

<template>
  <section class='esg-missions'>
    <SharedEsgHeader section='mission' />
    <div class='esg-section-content'>
      <p>{{ $t('esg.missions.description') }}</p>
      <div class='mission-quote'>
        <p class='quote'>
          “
        </p>
        <p class='quote-content'>
          {{ $t('esg.missions.quote') }}
        </p>
      </div>
      <p>{{ $t('esg.missions.listDescription') }}</p>
      <ul class='mission-list'>
        <EsgMissionsItem
          v-for='mission in missions'
          :key='mission.mission'
          :mission='mission.mission'
          :icon='mission.icon'
        />
      </ul>
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .esg-missions {
    .mission-quote {
      display: flex;
      justify-content: flex-end;
      padding: 36px;
      background: white;
      box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.06);
      border-radius: 18px;
      position: relative;
      .quote-content {
        font-style: italic;
        text-align: right;
        color: $primary;
        margin-bottom: 0;
        max-width: 86%;
      }
      .quote {
        font-size: 23rem;
        font-family: 'new-spirit';
        position: absolute;
        top: -94px;
        left: 20px;
        color: $primary;
        opacity: 0.1;
      }
    }
    .mission-list {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-s;
      padding-left: 0;
      margin: 0;
      @media(min-width: 1024px){
        padding-left: $spacer-md;
      }
    }
  }
</style>

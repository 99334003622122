<script setup>
  import EsgPartnersItem from './partners/EsgPartnersItem.vue';

  const images = ['franceInvest', 'pri']
</script>

<template>
  <div class='esg-partners'>
    <EsgPartnersItem
      v-for='image in images'
      :key='image'
      :image='image'
    />
  </div>
</template>

<style lang='scss'>
  .esg-partners {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px){
      flex-direction: row;
      justify-content: center;
    }
  }
</style>

<script setup>
  import logoMobileBlue from '@/../app/assets/images/logo/eg-logo-mobile-blue.svg';

  const routeNames = ['strategy', 'team', 'esg', 'contact']

  const emit = defineEmits(['emittedVisibleClass'])

  function closePanel(){
    emit('emittedVisibleClass', '')
  }
</script>

<template>
  <div class='panel panel--from-right js-panel-main'>
    <div class='panel-container'>
      <div class='panel-header'>
        <a
          class='panel-close close'
          href='#'
        >
          <i class='far fa-close close' />
        </a>
        <router-link
          :to="{ name: 'home'}"
          class='home-link'
        >
          <div class='panel-logo'>
            <img
              :src='logoMobileBlue'
              class='logo-home-link'
              data-test-id='app-header-logo'
              :alt="$t('app.header.home')"
            >
          </div>
        </router-link>
      </div>
      <div
        class='panel-content shared-tap-bar'
        data-test-id='shared-tap-bar-close-button'
        @click='closePanel'
      >
        <router-link
          v-for='routeName in routeNames'
          :key='routeName'
          :to='{ name: routeName }'
          class='shared-tap-link'
          data-test-id='shared-nav-bar-link'
        >
          {{ $t(`shared.navbar.${routeName}`) }}
        </router-link>
      </div>
      <div
        class='panel-footer'
        @click='closePanel'
      >
        <a class='cta-outline-primary'>
          <div class='cta-label'>
            {{ $t('shared.tapbar.panel.close') }}
          </div>
          <div class='cta-icon-wrapper'>
            <FontAwesomeIcon
              :icon="['fal', `xmark`]"
            />
          </div> 
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang='scss'>
.panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.6s;
  transition: visibility 0s 0.6s;
  z-index: 2000;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    -webkit-transition: background 0.3s 0.3s;
    transition: background 0.3s 0.3s;
  }
}

.panel-header, .panel-footer {
  background: white;
  border-radius: 9px;
  padding: $spacer-md;
}

.panel-footer {
display: flex;
flex-direction: column;
}
.panel-logo{
  display: flex;
  align-content: center;
  justify-content: center;
  img{
    max-width: 8rem;
  }
}
.panel-container {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  padding: $spacer-md;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
  display: flex;
  flex-direction: column;
  grid-gap: $spacer-md;
}

.panel--from-right .panel-container {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

.panel--is-visible .panel-container {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.panel-content {
  background: white;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: $spacer-md;
  overflow-x: scroll;
  /* smooth scrolling on touch devices */
  -webkit-overflow-scrolling: touch;
}
.shared-tap-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: $spacer-xl;
    .shared-tap-link {
      color: $dark;
      font-size: 2rem;
      font-family: $headings-font-family;
      font-weight: 500;
      text-decoration: none;
    }
  }
</style>
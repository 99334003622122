<script setup>
  import AppFooterCustomer from './footer/AppFooterCustomer.vue';
  import AppFooterLogo from './footer/AppFooterLogo.vue';
  import AppFooterNav from './footer/AppFooterNav.vue';
  import AppFooterOffice from './footer/AppFooterOffice.vue';
  import AppFooterUsefullLinks from './footer/AppFooterUsefullLinks.vue';

  const offices = ['montpellier', 'madrid']
</script>

<template>
  <footer class='app-footer'>
    <section class='responsive-container columns'>
      <div class='app-footer-column offices'>
        <AppFooterOffice
          v-for='office in offices'
          :key='office'
          :office='office'
        />
      </div>
      <div class='app-footer-column nav'>
        <AppFooterNav />
      </div>
      <div class='app-footer-column customer'>
        <AppFooterCustomer />
      </div>
    </section>
    <section class='section-end'>
      <AppFooterLogo />
      <AppFooterUsefullLinks />
    </section>
  </footer>
</template>

<style scoped lang='scss'>
  .app-footer {
    background-color: $dark;
    padding: $spacer-lg 0 8rem 0;
    @media(min-width: 1024px){
    padding: $spacer-lg 0;
    }

    .columns {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-gap:$spacer-md;
      @media(min-width: 1024px){
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .app-footer-column{
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-md;
    }
  }
</style>

import { defineStore } from 'pinia'

import benjamin from '@/../app/assets/images/img_team/benjamin.png';
import diego from '@/../app/assets/images/img_team/diego.png';
import eliud from '@/../app/assets/images/img_team/eliud.png';
// import essalha from '@/../app/assets/images/img_team/essalha.png';
import maeva from '@/../app/assets/images/img_team/maeva.png';
import marion from '@/../app/assets/images/img_team/marion.png';
import nicolas from '@/../app/assets/images/img_team/nicolas.png';
import sebastien from '@/../app/assets/images/img_team/sebastien.png';
import vincent from '@/../app/assets/images/img_team/vincent.png';

let id = 1;

export const useTeamMemberStore = defineStore('teamMember', {
  state: () => ({ 
    teamMembers: [
      {
        id: id++,
        name: 'Vincent Clerc',
        title: 'Dir. général & fondateur',
        descriptionKey: 'vincent-clerc',
        picture: vincent,
        active: false,
        linkedin: 'https://www.linkedin.com/in/vincent-clerc/',
        mail: 'vincent@enerfip.fr'
      },
      {
        id: id++,
        name: 'Nicolas Castan',
        title: 'Directeur de participations',
        descriptionKey: 'nicolas-castan',
        picture: nicolas,
        active: false,
        linkedin: 'https://www.linkedin.com/in/nicolas-castan-444972104/',
        mail: 'nicolas@enerfip.fr'
      },
      {
        id: id++,
        name: 'Marion Loiseau',
        title: 'RCCI',
        descriptionKey: 'marion-loiseau',
        picture: marion,
        active: false,
        linkedin: 'https://www.linkedin.com/in/marion-loiseau-330059115/',
        mail: 'marion@enerfip.fr'
      },
      {
        id: id++,
        name: 'Sébastien Jamme',
        title: 'Président & Fondateur',
        descriptionKey: 'sebastien-jamme',
        picture: sebastien,
        active: true,
        linkedin: 'https://www.linkedin.com/in/sebastienjamme/',
        mail: 'sebastien@enerfip.fr'
      },
      {
        id: id++,
        name: 'Maëva Granjon',
        title: "Directrice d'investissement",
        descriptionKey: 'maeva-granjon',
        picture: maeva,
        active: false,
        linkedin: 'https://www.linkedin.com/in/ma%C3%ABva-granjon-33531a159/',
        mail: 'maeva.g@enerfip.fr'
      },
      // {
      //   id: id++,
      //   name: 'Essalha Lakhoitri',
      //   title: 'Relations investisseurs',
      //   descriptionKey: 'essalha-lakhoitri',
      //   picture: essalha,
      //   active: false,
      //   linkedin: 'https://www.linkedin.com/in/essalha-lakhoitri-enerfip/',
      //   mail: 'essalha@enerfip.fr'
      // },
      {
        id: id++,
        name: 'Benjamin Zarrouk',
        title: 'Relations investisseurs',
        descriptionKey: 'benjamin-zarrouk',
        picture: benjamin,
        active: false,
        linkedin: 'https://www.linkedin.com/in/benjaminzarrouk/',
        mail: 'benjamin@enerfip.fr'
      },
      {
        id: id++,
        name: 'Eliud Garcia',
        title: 'Analyste financier',
        descriptionKey: 'eliud-garcia',
        picture: eliud,
        active: false,
        linkedin: 'https://www.linkedin.com/in/eliud-garcia-neto-83956812a/',
        mail: 'eliud@enerfip.fr'
      },
      {
        id: id++,
        name: 'Diego Marty',
        title: 'Analyste financier',
        descriptionKey: 'diego-marty',
        picture: diego,
        active: false,
        linkedin: 'https://www.linkedin.com/in/diego-marty-1b55a3179/',
        mail: 'diego@enerfip.fr'
      }
    ]
  }),
  getters: {
    getTeamMemberById: (state) => {
      return (id) => state.teamMembers.find(teamMember => teamMember.id === id);
    },
    getActiveTeamMember: (state) => {
      return () => state.teamMembers.find(teamMember => teamMember.active);
    }
  },
})

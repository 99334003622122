<script setup>
  import HomeValuesItem from '@/components/home/values/HomeValuesItem.vue';
  import qualiDesk from '@/../app/assets/images/img_team/quali-desk.png'

  const values = [
    { slug: 'dynamic', icon: 'arrow-up-right-dots' },
    { slug: 'diversified', icon: 'chart-network' },
    { slug: 'esg', icon: 'hand-holding-seedling' }
  ];
</script>

<template>
  <section class='home-values'>
    <div class='arc-title value-title'>
      <h2>{{ $t(`home.values.title`) }}</h2>
    </div>
    <div class='value-content'>
      <div class='value-list'>
        <HomeValuesItem
          v-for='value in values'
          :key='value'
          :icon='value.icon'
          :slug='value.slug'
        />
      </div>
      <img
        class='side-illustration'
        :src='qualiDesk'
        :alt="$t('home.values.qualiDesk')"
      >
    </div>
  </section>
</template>

<style scoped lang='scss'>
  .home-values {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-lg;
  }
  .value-title {
    align-items: center;
    text-align: center;
    @media(min-width: 1024px){
      align-items: start;
      text-align: left;
    }
  }
  .value-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media(min-width: 1024px){
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $spacer-md;
    }
    .value-list {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      grid-gap: $spacer-md;
    }
    .side-illustration {
      display: none;
      @media(min-width: 1024px){
      display: block; 
      }
    }
  }
</style>

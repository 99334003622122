<script setup>
  import avoidedCarbon from '@/../app/assets/images/img_home/avoided-carbon.webp';
  import financedProjects from '@/../app/assets/images/img_home/financed-projects.webp';
  import invested from '@/../app/assets/images/img_home/invested.webp';

  const imageMapper = {
    avoidedCarbon: avoidedCarbon,
    financedProjects: financedProjects,
    invested: invested
  }

  const darkFilter = 'linear-gradient(180deg, rgba(0, 0, 0, 0) 32.99%, rgba(0, 0, 0, 0.75) 85.07%)';
  const backgroundImageStyle = {
    'background-image': `${darkFilter}, url(${imageMapper[props.slug]})`,
  }

  imageMapper[props.slug];

  const props = defineProps({
    icon: { type: String, default: '' },
    slug: { type: String, default: '' }
  })
</script>

<template>
  <div
    class='home-figure'
    :style='backgroundImageStyle'
  >
    <div class='content-container'>
      <FontAwesomeIcon
        class='figure-icon'
        :icon="['fat', `${icon}`]"
      />
      <div class='data-section'>
        <p
          class='figure-title'
          data-test-id='home-figure-figure'
        >
          {{ $t(`home.figures.item.${slug}.figure`) }}
        </p>
        <p class='figure-description'>
          {{ $t(`home.figures.item.${slug}.description`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .home-figure {
    background-size: cover;
    background-position: bottom;
    background-color: $dark;
    border-radius: 18px;
    align-items: end;
    display: flex;
    @media(min-width: 1024px){
      height: 280px;
      background-position: center;
    }

    .content-container {
      color: white;
      display: flex;
      padding: 5% 10% 5% 10%;
      width: 100%;
      justify-content: center;
      .figure-icon {
        height: clamp(4rem, 6vw ,6rem );
        margin: auto 5% auto 0;
      }
      .data-section {
        font-family: $headings-font-family;
        .figure-title {
          color: $light;
          font-size: 48px;
          font-weight: 500;
        }
        .figure-description {
          font-size: 18px;
          color: white;
          font-weight: 300;
        }
      }
    }
  }
</style>
<script setup>
  import { computed } from 'vue';
  import teamBackground from '@/../app/assets/images/img_team/team-background.svg';
  import { useTeamMemberStore } from '@/stores/team-member';

  const teamMemberStore = useTeamMemberStore();

  const props = defineProps({
    teamMemberId: { type: Number, default: 0 }
  })

  const teamMember = computed(() => {
    return teamMemberStore.getTeamMemberById(props.teamMemberId);
  });

  function displayMember(teamMember) {
    teamMemberStore.teamMembers.forEach(teamMember => teamMember.active = false)
    teamMember.active = true;
  }

  function teamMemberPicture(teamMember) {
    return `background-image: url('${teamMember.picture}'), url('${teamBackground}')`
  }
</script>

<template>
  <div class='team-members-carousel-item'>
    <div
      class='frame'
      :class="teamMember.active ? 'frame-on' : 'frame-off'"
      data-test-id='team-member-frame'
      @click='displayMember(teamMember)'
    >
      <div
        class='member-photo-container'
        data-test-id='team-member-picture'
        :style='teamMemberPicture(teamMember)'
      />
    </div>
    <div class='member-info'>
      <span
        class='team-member-name'
        data-test-id='team-member-name'
      >
        {{ teamMember.name }}
      </span>
      <br>
      <span data-test-id='team-member-title'>
        {{ teamMember.title }}
      </span>
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .team-members-carousel-item {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-s;
    align-items: center;
    .frame {
      height: clamp(12rem, 30vw, 18rem);
      width: clamp(8rem, 20vw, 12rem);
      padding: $spacer-s;
      border-radius: 100rem;
      &-on {
        border: 4px solid $icons;
        opacity: 1;
      }
      &-off {
        border: 4px solid $dark;
        opacity: 0.75;
      }
      .member-photo-container {
        background-size: cover;
        background-position: center;
        border-radius: 86px;
        height: 100%; 
        width: 100%;
      }
    }
    .member-info {
      color: white;
      text-align: center;
      .team-member-name {
        font-size: 1.3rem;
        font-family: 'new-spirit';
      }
    }
  }
</style>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import TeamMembersCarouselItem from './carousel/TeamMembersCarouselItem.vue';
  import { useTeamMemberStore } from '@/stores/team-member';

  const teamMemberStore = useTeamMemberStore();

  const inner = ref(null);
  const innerStyles = ref({});
  const step = ref('');
  const transitioning = ref(false);

  const teamMembers = computed(() => teamMemberStore.teamMembers);

  function setStep() {
    const innerWidth = inner.value.scrollWidth;
    step.value = `${innerWidth / teamMemberStore.teamMembers.length}px`;
  }

  function previous() {
    if (transitioning.value) return;
    transitioning.value = true;
    moveRight()
    afterTransition(() => {
      const teamMember = teamMemberStore.teamMembers.pop();
      teamMemberStore.teamMembers.unshift(teamMember);
      resetTranslate();
      transitioning.value = false;
    })
  }

  function next() {
    if (transitioning.value) return
    transitioning.value = true
    moveLeft()
    afterTransition(() => {
      const teamMember = teamMemberStore.teamMembers.shift()
      teamMemberStore.teamMembers.push(teamMember)
      resetTranslate()
      transitioning.value = false
    })
  }

  function moveLeft() {
    innerStyles.value = {
      transform: `translateX(-${step.value}) translateX(-${step.value})`
    }
  }
  function moveRight() {
    innerStyles.value = {
      transform: `translateX(${step.value}) translateX(-${step.value})`
    }
  }
  function afterTransition(callback) {
    const transitionListener = () => {
      callback()
      inner.value.removeEventListener('transitionend', transitionListener)
    }
    inner.value.addEventListener('transitionend', transitionListener)
  }
  function resetTranslate() {
    innerStyles.value = {
      transition: 'none',
      transform: `translateX(-${step.value})`
    }
  }

  onMounted(() => {
    setStep();
    resetTranslate();
  })
</script>

<template>
  <div class='team-carousel'>
    <div
      ref='inner'
      class='carousel-item' 
      :style='innerStyles'
      data-test-id='team-members-carousel-inner'
    >
      <TeamMembersCarouselItem
        v-for='teamMember in teamMembers' 
        :key='teamMember.id'
        :team-member-id='teamMember.id'
      />
    </div>
    <FontAwesomeIcon
      :icon="['fas', 'chevron-left']"
      class='slide left'
      data-test-id='team-members-carousel-previous'
      @click='previous'
    />
    <FontAwesomeIcon
      :icon="['fas', 'chevron-right']"
      class='slide right'
      data-test-id='team-members-carousel-next'
      @click='next'
    />
  </div>
</template>

<style scoped lang='scss'>
  .team-carousel {
    display: flex;
    background-color: $dark;
    border-radius: 18px;
    overflow: hidden;
    position: relative;

    .carousel-item {
      display: flex;
      grid-gap: $spacer-md;
      padding: $spacer-md;
      align-items: center;
      white-space: nowrap;
      transition: transform 0.3s;

    }
    .slide {
      background: $icons;
      border: 6px solid rgba(210, 189, 149, 0.2);
      border-radius: 50px;
      font-weight: 900;
      font-size: 18px;
      height: 36px;
      width: 36px;
      color: $body-bg;
      cursor: pointer;
      position: absolute;
      top: calc(50% - 36px/2 - 12px);
      text-align: center;
    }
    .left {
      left: 18px;
    }
    .right {
      right: 18px;
    }
  }
</style>
<script setup>
  import SharedRowList from '@/components/shared/SharedRowList.vue';
  import SharedStrategyTitle from '@/components/shared/SharedStrategyTitle.vue';

  const goals = [
    { slug: 'co2', icon: 'temperature-high' },
    { slug: 'capacity', icon: 'bolt'},
    { slug: 'quantity', icon: 'house-building' }
  ]
</script>

<template>
  <section class='strategy-investment'>
    <SharedStrategyTitle
      icon='seedling'
      slug='strategyGoals'
    />
    <SharedRowList
      class='strategy-section'
      topic='esgMetrics'
      :items='goals'
    />
  </section>
</template>

<style scoped lang='scss'>
  .strategy-investment {
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-md;
    .strategy-section {
      color: $secondary;
    }
  }
</style>
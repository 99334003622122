import { createRouter, createWebHistory } from 'vue-router'
// import sitemapGenerator from '@/mixins/sitemap-generator.js';

import Contact from '@/pages/Contact.vue';
import Esg from '@/pages/Esg.vue';
import Home from '@/pages/Home.vue';
import LegalNotice from '@/pages/LegalNotice.vue';
import NotFound from '@/pages/NotFound.vue';
import Strategy from '@/pages/Strategy.vue';
import Team from '@/pages/Team.vue';

const routes = [
  { path: '/contact', name: 'contact', component: Contact },
  { path: '/esg', name: 'esg', component: Esg },
  { path: '/strategie-d-investissement', name: 'strategy', component: Strategy },
  { path: '/', name: 'home', component: Home },
  { path: '/mentions-legales', name: 'legalNotice', component: LegalNotice },
  { path: '/l-equipe', name: 'team', component: Team },
  { path: '/erreur-404', name: 'notFound', component: NotFound },
  { path: '/:catchAll(.*)', redirect: '/erreur-404' }
];

const router = createRouter({
  history: createWebHistory(''),
  routes: routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

// uncomment to update sitemap.xml manually
// console.log(sitemapGenerator(routes));

export default router;

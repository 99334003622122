<script setup>
  import SharedContactInfoItem from '@/components/shared/contact/info/SharedContactInfoItem.vue';

  const details = ['envelope', 'phone']
</script>

<template>
  <div class='shared-contact-info'>
    <div class='arc-title shared-contact-info-title'>
      <h2 data-test-id='shared-contact-info-title'>
        {{ $t('shared.contact.info.title') }}
      </h2>
    </div>
    <div class='contact-items'>
      <SharedContactInfoItem 
        v-for='detail in details'
        :key='detail'
        :detail='detail'
      />
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .shared-contact-info-title{
    align-items: center;
  }
  .shared-contact-info {
    h2 {
    text-align: center;
    color: $dark;
    }
    .contact-items {
      display: flex;
      flex-direction: column;
      text-align: center;
      grid-gap: $spacer-md;
      justify-content: center;
      align-items: center;
      color: $primary;
      @media (min-width: 1024px){
      display: flex;
      flex-direction: row;
      grid-gap: $spacer-md;
      }
    }
  }
</style>
<script setup>
  import { liquidLoader } from '@/mixins/liquid-loader.js';
  import { ref, onMounted } from 'vue';

  import StrategyGoals from '@/components/strategy/StrategyGoals.vue';
  import StrategyHeader from '@/components/strategy/StrategyHeader.vue';
  import StrategyInvestment from '@/components/strategy/StrategyInvestment.vue';
  import StrategyPosition from '@/components/strategy/StrategyPosition.vue';
  import StrategySidebar from '@/components/strategy/StrategySidebar.vue';
  import useMeta from '@/composables/useMeta';

  useMeta('strategy');

  const description = ref('');

  onMounted(() => {
    liquidLoader(description, 'strategy/description');
  });
</script>

<template>
  <div class='strategy responsive-container'>
    <StrategyHeader />
    <div class='strategy-main responsive-container'>
      <div class='strategy-content'>
        <p
          class='strategy-description'
          v-html='$sanitize(description)'
        />
        <StrategyInvestment />
        <StrategyPosition />
        <StrategyGoals />
      </div>
      <StrategySidebar class='sidebar-right' />
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .strategy {
    padding-top: $spacer-md;
    display: flex;
    flex-direction: column;
    grid-gap: $spacer-xl;
    @media(min-width: 1024px){
      padding-top: $spacer-xl;
    }
    img{
      width: 100%;
    }
    .strategy-main {
      display: flex;
      flex-direction: column;
      padding-bottom: $spacer-lg;
      @media(min-width: 1024px){
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: $spacer-md;
        justify-content: space-between;
      }
      .strategy-content {
        display: flex;
        flex-direction: column;
        grid-gap: $spacer-lg;
        .strategy-description {
          color: $dark;
          font-size: $typer-md;
          text-align: justify;
        }
      }
    }
  }
</style>

<script setup>
</script>

<template>
  <div class='strategy-sidebar'>
    <div class='cta-contact'>
      <h3
        class='contact-us'
        data-test-id='strategy-sidebar-contact-us'
      >
        {{ $t('strategy.sidebar.contactUs') }}
      </h3>
      <router-link
        :to="{ name: 'contact' }"
        class='cta-contact-link'
      >
        <span class='link-text'>
          {{ $t('strategy.sidebar.link') }}
        </span>
        <FontAwesomeIcon
          class='cta-contact-icon'
          :icon="['fal', 'circle-envelope']"
        />
      </router-link>
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .strategy-sidebar {
    display: none;
    @media (min-width: 1024px){
      display: flex;
      flex-direction: column;
    }

    .cta-contact {
      display: flex;
      flex-direction: column;
      grid-gap: $spacer-md;
      align-items: center;
      background-color: $dark;
      color: white;
      border-radius: 16px;
      padding: 30px;
      position: sticky;
      top: 80px;
      z-index: 2;
      .contact-us{
        color: white;
        font-weight: 400;
      }

      .cta-contact-link {
        display: flex;
        text-decoration: none;
        border: 1px solid white;
        border-radius: 28px;
        padding: 10px 10px 10px 20px;
        color: white;
        &:hover {
          background: white;
          color: $dark;
        }
        .link-text {
          margin-right: 26px;
          line-height: 30px;
        }
        .cta-contact-icon {
          height: 30px;
          background-color: white;
          border-radius: 20px;
          color: $dark;
        }
      }
    }
  }
</style>

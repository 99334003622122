<script setup>
  import LegalNoticeItem from '@/components/legal-notice/LegalNoticeItem.vue';
  import LegalNoticeLink from '@/components/legal-notice/LegalNoticeLink.vue';
  import SharedMainTitle from '../components/shared/SharedMainTitle.vue';

  import useMeta from '@/composables/useMeta';

  useMeta('legalNotice');

  const pdfFiles = ['cgu', 'privacy', 'rgpd'];
  const mdFiles = {
    enerfipGestion: 'legal-notice/enerfip-gestion', 
    hostContact: 'legal-notice/host-contact', 
    copyright: 'legal-notice/copyright', 
    personalData: 'legal-notice/personal-data', 
    responsabilities: 'legal-notice/responsabilities'
  };
</script>

<template>
  <div class='responsive-container legal-notice'>
    <SharedMainTitle page='legalNotice' />
    <div>
      <LegalNoticeItem 
        v-for='(filePath, fileName) in mdFiles'
        :key='fileName'
        :file-name='fileName'
        :file-path='filePath'
      />
    </div>
    <LegalNoticeLink
      v-for='pdfFile in pdfFiles'
      :key='pdfFile'
      :type='pdfFile'
    />
  </div>
</template>

<style scoped lang='scss'>
  .legal-notice{
    padding: 0 10% 40px 10%;
    color: $secondary;
  }
</style>